import './index.css';
import logo from '../../imgs/logo.png';

const Footer = () => {

  const toTop = () => {
    let timer;
    if (timer) {
      cancelAnimationFrame(timer);
    }
    timer = requestAnimationFrame(function fn(){
      const oTop = document.body.scrollTop || document.documentElement.scrollTop;
      if(oTop > 0){
        document.body.scrollTop = document.documentElement.scrollTop = oTop - 40;
        timer = requestAnimationFrame(fn);
      }else{
        cancelAnimationFrame(timer);
      }
    });
  }
  return (
    <div className="footer">
      <svg
        onClick={toTop}
        className="to-top"
        preserveAspectRatio="xMidYMid meet"
        data-bbox="20.5 20 159 160"
        viewBox="20.5 20 159 160"
        height="200"
        width="200"
        xmlns="http://www.w3.org/2000/svg"
        data-type="shape"
        role="img"
      >
        <g>
          <path d="M100 20c-43.906 0-79.5 35.817-79.5 80s35.594 80 79.5 80c43.908 0 79.5-35.817 79.5-80S143.908 20 100 20zm0 141.177c-33.523 0-60.794-27.444-60.794-61.177S66.477 38.823 100 38.823 160.794 66.267 160.794 100 133.523 161.177 100 161.177z"></path>
          <path d="M103.32 82.526c-1.786-2.127-4.852-2.127-6.639 0l-19.061 22.72c-2.648 3.155-.599 8.243 3.32 8.243h38.121c3.919 0 5.967-5.087 3.32-8.243l-19.061-22.72z"></path>
        </g>
      </svg>
      <a href="https://beian.miit.gov.cn/" target="_blank" className='police'>沪ICP备2021012555号-1</a>
      <img src={logo} alt="" />
    </div>
  )
}

export default Footer;
