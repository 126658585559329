import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Footer from './components/Footer'

const PageWrapper = (Component) => {
	return (props) => (
		<div style={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, marginTop: '18px'}}>
			<Component {...props} />
			<Footer />
		</div>
	)
}

export const routes = [
	{
		name: {
			zh: '零克咨询',
			en: 'HOME'
		},
		path: '/',
		component: PageWrapper(Home)
	},
	{
		name: {
			zh: '关于我们',
			en: 'ABOUT'
		},
		path: '/about',
		component: PageWrapper(About)
	},
	{
		name: {
			zh: '联系我们',
			en: 'CONTACT'
		},
		path: '/contact',
		component: PageWrapper(Contact)
	}
]
