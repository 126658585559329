import { memo } from 'react';
import PropTypes from 'prop-types';
import LazyImg from '../../../components/Img'
import style from './index.css'
import classnames from 'classnames'

const ContentItem = memo((props) => {
    const { title, text, bgPicPath, needMargin } = props
    return (
        <div className={classnames('more-content-item', {'more-content-item--needMargin': needMargin})}>
            <LazyImg className='more-content-item__pic' src={bgPicPath} alt='bgPic'/>
            <div className='more-content-item-container'>
                <Title
                    className='more-content-item__title'
                    titles={title}
                />
                <div className='more-content-item__content'>{text}</div>
            </div>
        </div>
    )
})

const Title = (props) => {
    const { className, titles } = props
    return (
        titles && titles.length && titles.map((item, index) => {
            return (
                <div className={className} key={index}>{item}</div>
            )
        })
    )
}
export default function MoreContent(props) {
    const { data } = props
    const { title, content } = data || {}

    return (
        <div className='more-content-container'>
            <style dangerouslySetInnerHTML={{ __html: style }} />
            <Title
                className='more-content__title'
                titles={title}
            />
            {content && content.length && (
                <div className='more-content-item-wrapper'>
                    {content.map((item, index) => {
                        const { title, text, bgPicPath } = item
                        const needMargin = Boolean(index % 2) 
                        return (
                            <ContentItem
                                key={index}
                                title={title}
                                text={text}
                                bgPicPath={bgPicPath}
                                needMargin={needMargin}
                            />
                        )
                    })}
                </div>
            )}
        </div>
    )
}

MoreContent.propTypes = {
    data: PropTypes.object.isRequired,
}

ContentItem.propTypes = {
    title: PropTypes.array.isRequired,
    text: PropTypes.string.isRequired,
    bgPicPath: PropTypes.string.isRequired,
}
Title.propTypes = {
    className: PropTypes.string.isRequired,
    titles: PropTypes.array.isRequired
}