import './index.css';
import {useEffect, useState} from "react";
import * as config from "./config";

const Contact = ({ lang }) => {
	const [langConfig, setLangConfig] = useState({})

	useEffect(() => {
		setLangConfig(() => config[lang])
	}, [lang])

	return (
		<div className="contact">
			<div className="img" style={{backgroundImage: `url(${langConfig.img})`}}>
				<div className="title">{langConfig.title}</div>
			</div>
			<div className="text">
				<div className="addr">{langConfig.address}</div>
				<div className="tel">{langConfig.tel}</div>
				<div className="email">{langConfig.email}</div>
			</div>
		</div>
	)
}

export default Contact
