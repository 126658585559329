import { useState, useEffect, useCallback } from 'react';
import * as config from './config';
import './index.css';
import classnames from 'classnames'

let scrollWidthUnit = 0
const About = ({ lang }) => {
	const [langConfig, setLangConfig] = useState({})
	const [curIndex, setCurIndex] = useState(0)
	const [scrollWidth, setScrollWidth] = useState(0)

	useEffect(() => {
		const elem = document.querySelector('.team-members-container')
		if(elem && elem.clientWidth) {
			scrollWidthUnit = 279 + (elem.clientWidth - 80 - 3 * 279) / 2
		}
	}, [ ])
	
	useEffect(() => {
		setLangConfig(() => config[lang])
	}, [lang])

	const handleClickIcon = useCallback((type) => {
		if(type === 'toRight') {
			if(curIndex >= langConfig.teams.length - 3) return
			setScrollWidth(scrollWidth - scrollWidthUnit)
			setCurIndex(curIndex + 1)
		} else {
			if(curIndex <= 0) return
			setScrollWidth(scrollWidth + scrollWidthUnit)
			setCurIndex(curIndex - 1)
		}
	}, [curIndex, scrollWidth, langConfig])

	return (
		<div className="page-about">
			<div className="title">{langConfig.title}</div>
			<div className='team-members-container'>
				{langConfig.teams && langConfig.teams.length > 3 && (
					<>
						<div
							className={classnames('team-members-icon', 'team-members-icon--left', {'team-members-icon--disable': curIndex <= 0})}
							onClick={() => handleClickIcon('toLeft')}
						/>
						<div
							className={classnames('team-members-icon', 'team-members-icon--right', {'team-members-icon--disable': curIndex >= langConfig.teams.length - 3})}
							onClick={() => handleClickIcon('toRight')}
						/>
					</>
				)}
				<div className='team-members-scroll'>
					<div className="team-members" style={{ transform: `translateX(${scrollWidth}px)` }}>
						{
							langConfig.teams && langConfig.teams.map((person, index) => {
								const { img, desc, name } = person || {}
								return (
									<div className="member" key={index}>
										<div className="pic" style={{backgroundImage: `url(${img})`}}>
											<div className="desc">
												<div className="desc__text">
													{desc && desc.length && desc.map((descItem, index) => {
														return (
															<div key={index}>
																{descItem}
															</div>
														)
													})}
												</div>
											</div>
										</div>
										<div className="name">{name}</div>
									</div>
								)
							})
						}
					</div>
				</div>
			</div>
			<div className="intros">
				{
					langConfig.intros && langConfig.intros.map((intro, index) => (
						<div key={index} className="intro">
							{
								index % 2 > 0 ? (
									<>
										<div className="pic" style={{backgroundImage: `url(${intro.img})`}}/>
										<div className="text">
											<div className="para-title">{intro.title}</div>
											<div className="para-subTitle">{intro.subTitle}</div>
											<div className="para-desc">{intro.desc}</div>
										</div>
									</>
								) : (
									<>
										<div className="text">
											<div className="para-title">{intro.title}</div>
											<div className="para-subTitle">{intro.subTitle}</div>
											<div className="para-desc">{intro.desc}</div>
										</div>
										<div className="pic" style={{backgroundImage: `url(${intro.img})`}}/>
									</>
								)
							}
						</div>
					))
				}
			</div>
		</div>
	)
}

export default About;
