import { memo } from 'react';
import PropTypes from 'prop-types';
import style from './index.css'
import classNames from 'classnames';

const Img = memo((props) => {
    const { path, picPosition } = props
    return (
        <div 
            className={classNames('text-container-img', {'text-container-img--side': picPosition === 'side'})}
            style={{backgroundImage: `url(${path})`}}
        />
    )
})

const ContentItem = memo((props) => {
    const { text } = props
    return (
        <div className='text-container__content'>{text}</div>
    )
})

export default function ContentWithPic(props) {
    const { data } = props
    const { title, content, leftPicPath, rightPicPath, picPosition } = data || {}

    return (
        <div className='text-container-wrapper'>
            <style dangerouslySetInnerHTML={{ __html: style }} />
            {leftPicPath && (
                <Img 
                    path={leftPicPath}
                    picPosition={picPosition}
                />
            )}
            <div className={classNames('text-container', {'text-container--side': picPosition === 'side'})}>
                {title && title.length && title.map((item, index) => {
                    return (
                        <div className='text-container__title' key={index}>{item}</div>
                    )
                })}
                {content && content.length && content.map((item, index) => {
                    const { text } = item
                    return (
                        <ContentItem
                            key={index}
                            text={text}
                        />
                    )
                })}
            </div>
            {rightPicPath && (
                <Img 
                    path={rightPicPath}
                    picPosition={picPosition}
                />
            )}
        </div>
    )
}

ContentWithPic.propTypes = {
    data: PropTypes.object.isRequired,
}

ContentItem.propTypes = {
    text: PropTypes.string.isRequired,
}

Img.propTypes = {
    path: PropTypes.string.isRequired,
    picPosition: PropTypes.string.isRequired,
}