import React, { useState } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import {
  HashRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { routes } from './router';
import classnames from 'classnames';
import LanguageChangeBtn from './pages/compontent/LanguageChangeBtn'

import logo from './imgs/logo.png';
import './App.css';
import './animate/fade.css';

const App = () => {
  const routeIndex = routes.findIndex((route) => window.location.hash.replace(/#(\w)*/, '$1') === route.path)
  const index =  routeIndex >= 0 ? routeIndex : 0
  const [ currentIndex, setCurrentIndex ] = useState(index)
  const [langType, setLangType] = useState('zh')

  const changeLangType = (type) => {
    setLangType(type)
  }
  return (
    <div className="App">
        <Router>
          <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
            <ul className="App-routes">
              {routes.map((route, index) => (
                <li
                  key={route.path}
                  className={classnames({
                    'App-route': true,
                    active: index === currentIndex
                  })}
                  onClick={() => setCurrentIndex(index)}
                >
                  <Link to={route.path}>{route.name[langType]}</Link>
                </li>
              ))}
            </ul>
            <LanguageChangeBtn 
              langType={langType}
              changeLangType={changeLangType}
            />
          </header>
          <Route render={({location}) => {
            return (
              <TransitionGroup id={'routeWrap'}>
                  <CSSTransition
                    key={location.pathname}
                    classNames='fade'
                    timeout={300}
                  >
                    <Switch location={location}>
                      {
                        routes.map(
                          (route) => <Route key={route.path} path={route.path} exact>
                            <route.component lang={langType} />
                          </Route>
                        )
                      }
                    </Switch>
                  </CSSTransition>
                </TransitionGroup>
            )
          }} />
        </Router>
    </div>
  )
}

export default App;
