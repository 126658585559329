import ContentWithPic from '../compontent/ContentWithPic'
import MoreContent from '../compontent/MoreContent'
import BackgroundPicWithText from '../compontent/BackgroundPicWithText'
import pageDate from './config'
import PropTypes from 'prop-types';
import style from './index.css'

const Home = (props) => {
	const { lang } = props
	const { bgPicData, pageContent, moreContent } = pageDate[lang] || {}

	return (
		<>
			<BackgroundPicWithText
				data={bgPicData}
			/>
			<div className='content-with-pic-wrapper'>
				<style dangerouslySetInnerHTML={{ __html: style }} />
				{pageContent && pageContent.map((item, index) => {
					return (
						<ContentWithPic
							key={index}
							data={item}
						/>
					)
				})}
			</div>
			{moreContent && (
				<MoreContent
					data={moreContent}
				/>
			)}
		</>
	)
}

export default Home

Home.propTypes = {
	lang: PropTypes.string.isRequired
}