import PropTypes from 'prop-types';
import classnames from 'classnames'
import './index.css'
import { useCallback, useState } from 'react';

export default function LanguageChangeBtn(props) {
    const [isActive, setIsActive] = useState(false)
    const { langType, changeLangType } = props
    const isChinese = langType === 'zh'

    const handleClick = useCallback(() => {
        setIsActive(!isActive)
    }, [isActive])

    const handleLangClick = useCallback((e, lang) => {
        e.stopPropagation()
        changeLangType(lang)
        setIsActive(false)
    }, [])

    return (
        <div className='change-btn-container'>
            <div 
                className={classnames('change-btn', {'change-btn--active': isActive})}
                onClick={handleClick}
            >
                {isChinese ? 'ZH' : 'EN'}
                <span className='change-btn__icon'></span>
                {isActive && (
                    <div className='select-lang-container'>
                        <div 
                            className='select-lang-item'
                            onClick={(e) => handleLangClick(e, 'zh')}
                        >
                            中文
                        </div>
                        <div 
                            className='select-lang-item'
                            onClick={(e) => handleLangClick(e, 'en')}
                        >
                            English
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

LanguageChangeBtn.propTypes = {
    langType: PropTypes.string.isRequired,
    changeLangType: PropTypes.func.isRequired
}
