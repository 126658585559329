import img3 from '../../imgs/building2.jpg';

export const en = {
  title: 'CONTACT US',
  img: img3,
  address: 'Room 511, Meriful Baixin Creative Park, No. 20, Lane 23, Yingao Road, Baoshan District, Shanghai',
  tel: '',
  email: 'Email:  request@lingkehr.com'
}

export const zh = {
  title: '联系我们',
  img: img3,
  address: '上海市宝山区殷高路23弄20号美丽华百新创意园511室',
  tel: '',
  email: '邮箱:  request@lingkehr.com'
}
