import react, { useEffect } from "react";
import lozad from "lozad";

export default function Img(props) {
    const { className, src, defaultSrc, alt, width, height, style } = props || {}
    useEffect(() => {
        const observer = lozad();
        observer.observe();
    }, [])

    return (
      <img
        className={`lozad ${className ? className : ''}`}
        data-src={src}
        alt={alt}
        width={width}
        height={height}
        style={style}
        src={defaultSrc}
      />
    )
}