import img1 from '../../imgs/people1.jpg'
import img2 from '../../imgs/people2.jpg'
import img3 from '../../imgs/people3.jpg'
import img4 from '../../imgs/people4.jpg'
import img5 from '../../imgs/people5.jpg'
import img6 from '../../imgs/people6.jpg'
import img7 from '../../imgs/people7.jpg'
import img8 from '../../imgs/people8.jpg'

export const en = {
  title: 'OUR TEAM',
  teams: [
    {
      name: 'Vivian',
      desc: [
        'Vivian is currently the senior consultant of LINGKEHR. Before joining LINGKEHR, Vivian served as Hrbp and senior manager of human resources in two well-known human resources companies in China. More than five years of working experience and human resources experience in the Internet industry. Be good at talent exploration and career consultation in Internet, e-commerce and other industries.',
        'Vivian is responsible for talent recruitment and consulting in the Internet, cross-border e-commerce, new retail and other industries.'
      ],
      img: img1
    },
    {
      name: 'Stephen',
      desc: ['Before the establishment of LINGKEHR, Stephen had 18 years of experience in the human resources industry. He had served in 51job, Zhaopin.com, Liepin.com, Qingtuan, Boss Zhipin and other leading Internet human resources companies in China. He had successively held the positions of sales director, regional manager, vice president and Coo. His industry experience covered senior talents and basic human resources projects, Committed to providing a full range of human resources services to many well-known Chinese enterprises.'],
      img: img2
    },
    {
      name: 'Yumi',
      desc: [
        'Yumi is the senior project manager of LINGKEHR. She has more than 10 years of recruitment experience in the Internet industry. She has worked in well-known Internet companies, served LGC, Pfizer, Sinopharm, Alibaba, Shenzhou black eagle, Nestle Wyeth and other well-known customers, and has an in-depth understanding of the industry.',
        'Yumi is responsible for the overall project control of customers in the Internet and pharmaceutical industry in Lingke as a project manager.'
    ],
      img: img3
    },
    {
      name: 'Jerry',
      desc: [
        'Jerry focus on the recruitment field for ten years. Served in well-known domestic financial and real estate companies as a recruitment expert. Good at recruitment system construction, recruitment process combing, recruitment team management, and mid-to-high-end talent search.',
        'He Joined LINGKEHR in early 2021, mainly responsible for the search of talents for various marketing positions and senior management positions.'
      ],
      img: img4
    },
    {
      name: 'Rachel',
      desc: [
        "Prior to joining LINGKEHR as a Senior Adviser, Rachel had been engaged in technology research and development for 13 years in a number of well-known companies. She works as a test supervisor in MOTO's Shanghai subsidiary, as a TPM in Aliyun OS, and as a senior test engineer in Ctrip. She is involved in communications, IOV, Internet and other industries, familiar with the whole development process and functions of the technical team. ",
        'Rachel is responsible for the recruitment and consultation of technology, products and R & D positions in LINGKEHR.'
      ],
      img: img5
    },
    {
      name: 'Seven',
      desc: [
        'Before joining LINGKEHR, Seven worked as a sales manager in Liepin Shenzhen Branch, and then joined the part-time campus platform of the Qingtuan to continue to be responsible for the sales business and delivery field. Experienced in recruitment and delivery process, team management.',
        'Seven is responsible for recruiting and consulting marketing talents at LINGKEHR.'
      ],
      img: img6
    },
    {
      name: 'Dummer',
      desc: ['Dummer joined LINGKEHR as a senior headhunting consultant in 2021. Before that, he hired a senior sales consultant in Liepin.com, and then joined the college student campus part-time platform of Qingtuan as a sales manager. Mr. Chen is good at talent recruitment of Sales&marketing and customer service position.'],
      img: img7
    },
    {
      name: 'Asher',
      desc: ['Asher joined LINGHKEHR in March 2018 and worked as Senior Adviser. Before, he was Business Manager in Qingtuan and Boss Zhipin. In LINGKEHR, Asher is in charge of automatic driving, Ai, e-commerce and Saas business, which are oriented to all industries. He has rich experience in business development, customer management, recruitment process and flow thinking.'],
      img: img8
    }
  ],
  intros: [
    {
      title: 'Source of talents',
      subTitle: '',
      desc: 'Our talent pool is based on a variety of sources. Under the guidance of lingkehr core values, our talent services are recommended and trusted by many candidates, and are willing to share and recommend.',
      img: require('../../imgs/about-1.jpg').default
    },
    {
      title: 'Cooperative partner',
      subTitle: '',
      desc: 'lingkehr is committed to helping growing and changing companies meet their talent needs and development. Establish partnerships with customers to serve selected customers in specific industries. We focus on developing and nurturing deep and long-term relationships with our customers, because strong relationships lead to the most in-depth understanding, more diversified and informed choices, and extensive access to the most targeted talents.',
      img: require('../../imgs/about-2.jpg').default
    },
    {
      title: 'Consultant standards',
      subTitle: '',
      desc: "lingkehr's consultant selection focuses more on practical work background and experience than simple academic theory. We believe that the same knowledge structure will bring different experiences, feelings and contents in different scenarios. Therefore, the consultant's actual work experience helps to understand the real needs of partners more concretely, and give candidates more complete and clear suggestions and opinions on current and future scenarios.",
      img: require('../../imgs/about-3.jpg').default
    },
  ]
}

export const zh = {
  title: '我们团队',
  teams: [
    {
      name: '辛琪',
      desc: [
        '辛女士现任零克咨询的高级顾问，在加入零克人才之前，辛女士在国内两家人力资源知名公司担任HRBP及人力资源高级经理。在互联网行业有超过五年的从业经验和人力资源经验。擅长互联网，电子商务等行业的人才探寻和职业咨询。',
        '辛女士在零克咨询负责互联网，跨境电商，新零售等行业的人才招募及咨询。'
      ],
      img: img1
    },
    {
      name: '王翔',
      desc: ['在创办零克咨询前，王翔先生有18年人力资源行业从业经验，曾服务于51job，智联招聘，猎聘网，青团社，boss直聘等国内领先互联网人力资源公司，历任销售总监，大区经理，副总裁，COO等职位，行业经验覆盖高级人才和基础人力项目，致力于众多中国知名企业提供全方位的人力资源服务。'],
      img: img2
    },
    {
      name: '朱郡怡',
      desc: [
        '朱女士现担任零克咨询高级项目经理，拥有超过10年的互联网行业招聘经验，曾任职于知名互联网公司，并服务过LGC、辉瑞、国药、阿里、神舟黑鹰、雀巢惠氏等多家知名客户，对于行业有深入的了解。',
        '朱女士在零克咨询以项目经理的角色负责互联网及医药行业客户整体项目把控。'
      ],
      img: img3
    },
    {
      name: '周冲',
      desc: [
        '周先生专注招聘领域十年时间。曾服务于国内知名金融及地产公司，担任招聘专家岗位。擅长招聘体系搭建、招聘流程梳理、招聘团队管理以及中高端人才寻访等。',
        '2021年初加入零克，主要负责各类营销岗位以及高级管理岗位的人才寻访。'],
      img: img4
    },
    {
      name: '金旭',
      desc: [
        '金女士在加入零克成为高级顾问前，她在多家知名公司从事了13年技术研发工作。在MOTO上海子公司担任测试主管，在阿里云OS中担任TPM一职，在携程中担任高级测试工程师。她涉及通讯、车联网、互联网等多个行业，熟悉技术团队整个开发流程和职能。',
        '金女士在零克咨询负责技术，产品，研发类岗位的招募和咨询。'
      ],
      img: img5
    },
    {
      name: '吴明',
      desc: [
        '吴先生在加入零克人才之前在猎聘深圳分公司担任销售经理一职，而后加入青团社大学生校园兼职平台，继续负责销售业务及交付领域。对招聘及交付流程，团队管理有着丰富的经验。',
        '吴先生在零克咨询负责市场营销人才招募和咨询。'
      ],
      img: img6
    },
    {
      name: '陈奕恺',
      desc: ['陈先生2021年加入零克咨询担任高级猎头顾问，在此之前，他在猎聘高级销售顾问，而后加入青团社大学生校园兼职平台担任销售经理。陈先生擅长营销体系，客服体系的人才招募工作。'],
      img: img7
    },
    {
      name: '曹云俊',
      desc: ['曹先生于2018年3月作为高级顾问加入Ling Ke，在此之前，他是青团社、Boss直聘的业务经理。 Asher负责Ling Ke的自动驾驶/Ai/电商/Saas业务板块，面向所有行业。他在业务开发，客户管理，招聘流程，流量思维拥有着丰富的经验。'],
      img: img8
    }
  ],
  intros: [
    {
      title: '人才来源',
      subTitle: '',
      desc: '我们的人才库基于多种渠道来源组成。包括传统人力资源互联网平台，专业社交领域，行业私域等，在lingkehr核心价值观的指引下，我们的人才服务受到众多候选人的推荐和信任，进而乐于分享和推荐。',
      img: require('../../imgs/about-1.jpg').default
    },
    {
      title: '合作伙伴',
      subTitle: '',
      desc: 'lingkehr致力于协助成长型公司及变革公司的人才需求及发展。与客户建立伙伴关系，为特定行业的选定客户提供服务。我们专注于发展和培育与客户的深厚、长期的关系，因为牢固的关系会带来最深入的了解、更多样化和知情的选择，以及广泛接触最有针对性的人才。',
      img: require('../../imgs/about-2.jpg').default
    },
    {
      title: '顾问标准',
      subTitle: '',
      desc: 'lingkehr的顾问甄选更注重实际的工作背景及经验而非简单的学术理论。我们认为，相同的知识结构在不同的场景下会带来不同的经验，感受和内容。所以，顾问的实际工作经历，有助于更具象的了解合作伙伴的真实需求，并对当前及未来的场景给予候选人更完整清晰的建议和意见。',
      img: require('../../imgs/about-3.jpg').default
    },
  ]

}
