import PropTypes from 'prop-types';
import LazyImg from '../../../components/Img'
import style from './index.css'


export default function BackgroundPicWithText(props) {

    const { data } = props
    const { picPath, text, subText } = data || {}

    return (
        <div className='background-pic-container'>
            <style dangerouslySetInnerHTML={{ __html: style }} />
            <div className="background-pic-container__img">
                <LazyImg src={picPath} alt='bgPic'/>
                <div className='background-pic-container__text'>
                    {text}
                    <span className='background-pic-container__subText'>{subText}</span>
                </div>
			</div>
        </div>
    )
}

BackgroundPicWithText.prototype = {
    data: PropTypes.object.isRequired
}