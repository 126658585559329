const pageDate = {
    zh: {
        bgPicData: {
            picPath: require('../../imgs/building1.jpg').default,
            text: 'LINGKEHR',
            subText: 'MAKE EVERY ONE BETTER'
            
        },
        pageContent: [
            {
                title: ['关于我们'],
                content: [
                    {
                        text: 'lingkehr成立于2012年。拥有20多位各领域专家顾问，他们平均在互联网、人力资源、制造业、传媒、教育和房地产等不同领域拥有10-15年的工作经验。多年来，顾问在人才寻猎、行业调查、管理咨询等方面提供服务。'
                    },
                    {
                        text: 'lingkehr的人才库汇集了优秀的行业人才和专家。他们的背景从互联网运营、市场营销、人力资源管理、组织诊断、工业设计、新媒体、医生、企业高管，到各个领域的资深人才，各不相同。lingkehr旨在以专业视角，依托全局人才视野，通过专业的行业背景和经验，为企业和候选人建立一座良好沟通，互信共赢的桥梁。'
                    },
                    {
                        text:'十年来，lingkehr累计服务了近百家中大型企业，近3万优秀的职场人和行业精英。 公司信奉真心，用心，走心的原则，踏实经营每一家企业的信任和委托，同时视每一位候选人的诉求为己任。 我们不做简单的简历搬运工，我们尊重每一次沟通，满怀敬畏之心，用自己的力量，让工作更美好。'
                    }
                ],
                leftPicPath: require('../../imgs/office1.jpg').default,
                rightPicPath: null,
                picPosition: 'left'
            },
            {
                title: ['人才寻猎'],
                content: [
                    {
                        text: "lingkehr人才寻猎即猎头服务，助力企业搜寻高管、甄选中层、招募精英，同时助力人才获取更优质岗位，实现双向匹配。服务覆盖各行各业、聚焦互联网，人力资源，工业制造，医疗四大领域，专攻IT技术、市场营销、财务会计、中台运营职能管理等类别岗位需求。lingkehr将服务重心关注在成长型公司，重点助力高速成长企业的人才需求和服务。"
                    }
                ],
                leftPicPath: null,
                rightPicPath: require('../../imgs/office2.jpg').default,
                picPosition: 'right'
            },
            {
                title: ['管理', '咨询'],
                content: [
                    {
                        text: "结合我们的专家顾问和人才体系，我们能够为我们的企业客户提供定制的市场研究和管理咨询服务。我们的顾问团队由多个行业的专家及讲师组成。有别于一般的管理培训，lingkehr始终信奉亲历者大于旁观者的理念，与数家国内知名企业建立了成功的合作关系，为他们提供最前沿的人才信息，商业预测，管理培训，组织诊断等服务。"
                    }
                ],
                leftPicPath: require('../../imgs/office3.jpg').default,
                rightPicPath: require('../../imgs/office4.jpg').default,
                picPosition: 'side'
            }
        ],
        moreContent: {
            title: ['更多内容'],
            content: [
                {
                    title: ['RPO'],
                    text: 'lingkehr招聘流程外包服务（RPO）根据企业的具体需求，有针对性地提供部分或全流程招聘外包服务。作为一家专业的招聘流程外包服务商，我们旨在整合战略、技术、资源及供应商管理，以提供高效招聘流程服务。在服务过程中，我们将作为企业战略合作伙伴与你的内部团队紧密合作，通过流程设计、制定批量化岗位招聘战略、人才寻访、人才选拔、招聘决策等服务，帮助企业提升招聘效率，提高招聘质量，同时降低招聘成本。',
                    bgPicPath: require('../../imgs/more-1.jpg').default,
                },
                {
                    title: ['驻场服务'],
                    text: 'lingkehr根据客户的需求及实际的业务场景，提供阶段化的驻场服务。旨在与企业更紧密的合作及了解，同时分担客户工作压力及提升效率。在驻场期间，我们的顾问会针对招聘流程，面试邀约，人才报告，访谈等工作给予企业高效，专业的服务。',
                    bgPicPath: require('../../imgs/more-2.jpg').default,
                },
                {
                    title: ['招聘诊断'],
                    text: '基于长期的人才服务经历，lingkehr依托专业顾问的实际经验，为企业HR部门提供招聘渠道的解答，分析，诊断，建议等服务。助力企业优化外部招聘渠道效率和成本。',
                    bgPicPath: require('../../imgs/more-3.jpg').default,
                },
                {
                    title: ['职业发展'],
                    text: 'lingkehr乐于协助职场人更清晰自身的发展路径规划，所以我们为求职者提供一对一的职业发展咨询，邀请行业内资深从业专家为有志于本行业或跨行业的职场人提供建议，分享，答疑，规划等服务',
                    bgPicPath: require('../../imgs/more-4.jpg').default,
                }
            ]
        }
    },
    en: {
        bgPicData: {
            picPath: require('../../imgs/building1.jpg').default,
            text: 'LINGKEHR',
            subText: 'MAKE EVERY ONE BETTER'
        },
        pageContent: [
            {
                title: ['About us'],
                content: [
                    {
                        text: 'lingkehr was founded in 2012. With more than 20 experts and consultants in various fields, they have an average of 10-15 years of working experience in different fields such as Internet, human resources, manufacturing, media, education and real estate. Over the years, consultants have provided services in talent hunting, industry investigation, management consulting, etc.'
                    },
                    {
                        text: "lingkehr's talent pool brings together excellent industry talents and experts. Their backgrounds vary from Internet operation, marketing, human resource management, organizational diagnosis, industrial design, new media, doctors, business executives to senior talents in various fields. lingkehr aims to build a good communication, mutual trust and win-win bridge for enterprises and candidates from a professional perspective, relying on the overall talent vision, and through professional industry background and experience."
                    },
                    {
                        text:'In the past ten years,lingkehr has served nearly 100 large and medium-sized enterprises and nearly 30000 outstanding professionals and industry elites. The company believes in the principle of sincerity, attentiveness and carelessness, and manages the trust and entrustment of every enterprise in a down-to-earth way. At the same time, it regards the demands of every candidate as its own responsibility. We do not do a simple resume Porter, we respect every communication, full of awe, with their own strength, make the work better.'
                    }
                ],
                leftPicPath: require('../../imgs/office1.jpg').default,
                rightPicPath: null,
                picPosition: 'left'
            },
            {
                title: ['Talent hunting'],
                content: [
                    {
                        text: "lingkehr talent hunting is headhunting service, which helps enterprises to search for senior executives, select middle-level talents and recruit elites. At the same time, it helps talents to obtain better positions and realize two-way matching. The service covers all walks of life, focusing on the Internet, human resources, industrial manufacturing, medical four major fields, specializing in IT technology, marketing, financial accounting, operation function management and other types of post needs. lingkehr focuses its services on growing companies, focusing on helping the talent demand and services of fast-growing enterprises."
                    }
                ],
                leftPicPath: null,
                rightPicPath: require('../../imgs/office2.jpg').default,
                picPosition: 'right'
            },
            {
                title: ['Management', 'consulting'],
                content: [
                    {
                        text: "Combined with our expert consultant and talent system, we can provide customized Market Research and management consulting services for our enterprise customers. Our consultant team is composed of experts and lecturers from various industries. Different from the general management training,lingkehr always believes in the idea that the experience is greater than the bystander. It has established a successful cooperative relationship with several domestic well-known enterprises, providing them with the most cutting-edge talent information, business forecast, management training, organization diagnosis and other services."
                    }
                ],
                leftPicPath: require('../../imgs/office3.jpg').default,
                rightPicPath: require('../../imgs/office4.jpg').default,
                picPosition: 'side'
            }
        ],
        moreContent: {
            title: ['More content'],
            content: [
                {
                    title: ['RPO'],
                    text: 'provides part or whole process recruitment outsourcing services. As a professional recruitment process outsourcing service provider, we aim to integrate strategy, technology, resources and supplier management to provide efficient recruitment process services. In the service process, we will work closely with your internal team as a strategic partner of the enterprise. Through process design, development of mass recruitment strategy, talent search, talent selection, recruitment decision-making and other services, we will help enterprises improve recruitment efficiency, improve recruitment quality and reduce recruitment costs.',
                    bgPicPath: require('../../imgs/more-1.jpg').default,
                },
                {
                    title: ['On site service'],
                    text: "lingkehr provides staged on-site services according to the needs of customers and actual business scenarios. It aims to cooperate and understand more closely with enterprises, share customers' work pressure and improve efficiency. During the stay, our consultants will provide efficient and professional services for enterprises in terms of recruitment process, interview invitation, talent report, interview, etc",
                    bgPicPath: require('../../imgs/more-2.jpg').default,
                },
                {
                    title: ['Recruitment', 'diagnosis'],
                    text: 'Based on long-term talent service experience,lingkehr relies on the practical experience of professional consultants to provide HR departments with recruitment channel solutions, analysis, diagnosis, suggestions and other services. Help enterprises optimize the efficiency and cost of external recruitment channels.',
                    bgPicPath: require('../../imgs/more-3.jpg').default,
                },
                {
                    title: ['Career development'],
                    text: 'lingkehr is willing to help employees to make their own development path more clear, so we provide one-to-one career development consultation for job seekers, and invite senior industry experts to provide advice, sharing, question answering, planning and other services for employees who are interested in their own industry or cross industry.',
                    bgPicPath: require('../../imgs/more-4.jpg').default,
                }
            ]
        }
    }
}

export default pageDate